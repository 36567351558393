import "./style.scss";

import { InputWrapper } from "@amzn/stencil-react-components/form";
import { Container } from "@amzn/stencil-react-components/layout";
import { ScreenReaderOnly } from "@amzn/stencil-react-components/screen-reader-only";
import { SearchField, useSearch } from "@amzn/stencil-react-components/search";
import { TrafficFrom } from "common/helpers/traffic-from";
import { CatalogItemSimplified } from "common/types/catalog-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { debounce } from "lib/helpers/debounce";
import { SearchService } from "lib/services/search-service";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { AutocompleteSearchResult } from "../AutocompleteSearchResult";

export interface SearchBoxProps {
  /**
   * The width of the Search Box
   */
  width?: string;
  /**
   * The query string
   */
  queryText?: string | null;
}

export interface SearchBoxItem {
  name: string;
  itemId: string;
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({ width, queryText }) => {
  let placeholderText = getString("searchBox.placeholder");

  if (!queryText) queryText = "";

  if (location.pathname == "/") {
    // If it is the home page, should show a different placeholder text
    placeholderText = getString("searchBox.placeholder_home");
  }

  const [query, setQuery] = useState(queryText);
  const [totalItemsCount, setTotalItemsCount] = useState<number | undefined>(undefined);

  // For searching
  const getSearchResultsFromAPI = async (searchText: string): Promise<CatalogItemSimplified[]> => {
    setQuery(searchText);
    if (!(searchText?.length > 2)) {
      return [];
    }

    const results = await SearchService.current.Search({
      text: searchText,
      pageSize: 5,
      and: true,
      includeFacets: false,
    });
    setTotalItemsCount(results?.totalResults);

    if (results.items) return results.items;
    else return [];
  };

  const getSearchResultsDebounced = useCallback(debounce(getSearchResultsFromAPI, 1000)[0], []);

  const { searchFieldProps } = useSearch({
    onQueryChange: getSearchResultsDebounced,
  });

  const resultCountTextHandler = () => {
    return totalItemsCount == null ? getString("searchBox.noResults") : `${totalItemsCount} results`;
  };

  const history = useHistory();

  // How to display the result once its selected
  const resultToStringHandler = (result: SearchBoxItem) => result.itemId;

  // When an item is selected from the autocomplete
  const onResultClickHandler = (option: SearchBoxItem): void => {
    const itemTaxId = ItemTaxonomyId.Parse(option.itemId);
    history.push(`/items/${itemTaxId.getUrlSuffix()}?from=${TrafficFrom.Autocomplete}`);
  };

  // When the view all results is selected
  const onListActionButtonClickHandler = () => {
    history.push(`/search?query=${query}`);
  };

  // textInput must be declared here so the ref can refer to it
  const textInput = useRef<HTMLInputElement>(null);

  /* istanbul ignore next */
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    history.push(`/search?query=${textInput.current?.value}`);
    textInput.current?.blur();
    return false;
  };

  return (
    <Container isFullWidth={true}>
      <form onSubmit={onSubmitHandler} target="/search">
        <InputWrapper
          id="search-box"
          labelText="Search"
          renderLabel={({ htmlFor, children }) => (
            <ScreenReaderOnly>
              <label htmlFor={htmlFor}>{children}</label>
            </ScreenReaderOnly>
          )}
        >
          {(inputProps: any) => (
            <SearchField
              ref={textInput}
              width={width}
              data-cy="search-input"
              placeholder={placeholderText}
              renderResult={AutocompleteSearchResult}
              resultToString={resultToStringHandler}
              resultCountText={resultCountTextHandler}
              onResultClick={onResultClickHandler}
              onListActionButtonClick={onListActionButtonClickHandler}
              shouldAutocomplete={false}
              hasSubmitButton={true}
              {...inputProps}
              {...searchFieldProps}
            />
          )}
        </InputWrapper>
      </form>
    </Container>
  );
};
